// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

$dokki: #2f292b;
$guli: #f2c909;
$appelsinuguli: #f27709;
$hviti: #f3f3f3;
$grar: #777777;
$blai: #0396f3;

html {
	background: $dokki;
}

body {
	font-family: 'Open Sans', sans-serif;
}

hr {
	clear: both !important;
	display: block;
}

body a:active,
body a:hover,
body a {
	color: $grar
}

.white {
	color: #FFF !important;
}
.orange {
	color: $appelsinuguli !important;
}

#topbar {
	background: $dokki;

	#logo {
		z-index: 10000;
		position: absolute;
		left: -75px;
		top: 0px;

		@media only screen and (max-width : 1400px) {
			display: none;
		}
	}


	#logo2 {
		position: absolute;
		z-index: 2;
		top: -12px;
		left: 30px;
		display: none;
		
		@media (max-width:1399px) and (min-width:600px) {
			display: block;
		}
	}

	.renningur {
		@media (max-width:1399px) and (min-width:600px) {
			padding-left: 80px;
		}
	}
}

div.bannerefni {
	background: rgba(0,0,0,0.35);
	padding: 30px;
	display: inline-block;
	height: 100% !important;
	width: auto;

	/*position: absolute;
	bottom: 0;
	left: 0;*/

	h1 {
		padding: 0;
		color: #FFF;
	}
	h2, h3, h4, h5, h6 {
		padding: 0;
		color: $guli;
	}

	@media only screen and (max-width : 1400px) {
		margin-left: 30px;
	}

	@media only screen and (max-width : 1200px) {
		padding: 20px;

		font-size: 14px;

		h1 {
			font-size: 24px;
			line-height: 28px;
		}
		h3 {
			font-size: 14px;
			line-height: 18px;
		}
	}
}

button.takki:active,
a.takki:active,
button.takki:hover,
a.takki:hover,
button.takki,
a.takki {
	text-decoration: none;
	background: $guli;
	border: none !important;
	font-weight: 700;
	text-transform: uppercase;
	padding: 0.8em 1.6em;
	color: $dokki;
	text-align: center;
}

#top {
	h4#textlogo {
		a {
			color: #222;
			text-decoration: none;
		}
		a:hover {
			text-decoration: none;
		}
		@media (max-width:1399px) and (min-width:600px) {
			padding-left: 70px;
		}

		font-weight: 600 !important;
		padding: 0;
		padding-top: 15px;
		margin: 0;
	}

	background: $hviti;
	border-bottom: 1px solid $grar;
}

body {
	h1, h2, h3, h4, h5, h6 {
		font-family: 'Open Sans', sans-serif;
		font-weight: 300;
		text-transform: uppercase;
		font-weight: 300 !important;
	}
}

#main {
	min-height: 300px;
	background: #FFF;
	padding-top: 30px;
	padding-bottom: 30px;
}

div.efst-haegri {
	background: #000;
	float: right;

	.social {
		float: left;

		ul {
			list-style: none;
			padding: 0;
			margin: 0;

			li {
				float: left;
				padding: 0;
				margin: 0;
				color: #FFF;

				a {
					display: block;
					padding: 0.5em;
					color: #FFF;
				}
			}
		}
	}

	.tungumal {
		line-height: 2.4em;
		color: #FFF;
		font-weight: normal;
		float: left;

		a {
			float: left;
			padding: 0;
			padding-left: 0.5em;
			padding-right: 0.5em;
			margin: 0;
			color: #FFF;

			&.active {
				font-weight: bolder;
			}
		}
	}
}

.uk-offcanvas-bar {
	box-shadow: 0px 0px 16px rgba(0,0,0,0.25);
}

nav {
	&#pagemenu {
		display: block !important;
		clear: both !important;
		width: 100% !important;

		li {
			margin-right: 0.8em;
			margin-bottom: 0.8em;
			float: left;

			a {
				border: 1px solid $blai;
				display: block !important;
				font-weight: 700;
				padding: 0.6em 1em;
				color: $blai !important;
				text-decoration: none !important;
				text-transform: uppercase !important;
			}
			a:hover {
				text-decoration: none !important;
			}

			&.active, &:hover {
				a {
					color: #FFF !important;
					transition: color 0.25s;
				}
				
				background: $blai;
				transition: background 0.25s;
			}
		}
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;

		&.renningur {
			@media only screen and (max-width : 1100px) {
				display: none;
			}

			li {
				padding-right: 10px;
				font-size: 0.65em;
				line-height: 1.2em;
				letter-spacing: 0.01%;
				text-align: center;
				padding-top: 0.5em;
				float: left;

				a {
					display: block;
					text-align: center;
					color: #FFF;
				}
			}
		}

		&.mobile {
			color: #FFF;
			border-right: 1px solid $grar;

			li {
				padding: 0;
				border-bottom: 1px solid $grar;

				a {
					background: $hviti;
					transition: background 0.10s;
					padding: 0;
					margin: 0;
					text-transform: uppercase;
					text-decoration: none;
					display: block;
					font-weight: 700;
					color: $grar;
					padding: 2em 1.5em;
				}
				a.active,
				a:hover {
					text-decoration: none;
					color: #FFF;
					//background: darken($hviti, 10);
					background: $blai;
					transition: background 0.10s, color 0.10s;
				}
			}
		}

		&.top {
			float: right;

			li {
				float: left;
				padding: 0;
				margin: 0;
				border-left: 1px solid $grar;

				a.active,
				a:hover {
					text-decoration: none;
					color: #FFF;
					//background: darken($hviti, 10);
					background: $blai;
					transition: background 0.10s, color 0.10s;
				}

				a {
					@media only screen and (max-width : 1200px) {
						font-size: 13px;
						padding: 2em 1em;
					}

					background: $hviti;
					transition: background 0.10s;
					padding: 0;
					margin: 0;
					text-transform: uppercase;
					text-decoration: none;
					display: block;
					font-weight: 700;
					color: $grar;
					padding: 2em 1.5em;
				}
			}

			li:last-child {
				border-right: 1px solid $grar;
			}
		}		
	}

	li {
		padding: 0;
		margin: 0;
	}
}

#auglysing {
	padding-top: 30px;
	padding-bottom: 30px;
	color: #FFF;
	background: $dokki;
}
#fotur {
	padding-top: 30px;
	padding-bottom: 30px;
	color: #FFF;
	background: $dokki;
}

#styrktaradilar {
	blockquote {
		color: #FFF !important;
		border: none !important;
	}
	color: #FFF !important;
}


.tafla {
	border: none !important;
	width: 100%;
	font-weight: normal;
	padding: 0;
	margin: 0;

	caption {
		text-align: left;
		font-style: italic;
		display: table-cell;
		vertical-align: bottom;
		font-size: 0.9em;
		height: 60px !important;
	}

	tr, td {
		border: none !important;
	}

	tr {
		&.einkunn-comment {
			td {
				background: #DDD;
			}
		}
	}

	td {
		font-weight: normal;
		background: #EEE;
	}

	td:last-child {
		font-weight: bold;
	}

}




#staff {
	.img-container {
		margin-bottom: 0.2em;
		text-align: center;

		img {
			line-height: 100% !important;
			padding: 0;
			margin: 0;
			border-radius: 4px;
		}

		border-radius: 4px;
		overfow: hidden;
		min-height: 150px;
		clear: both;
		display: inline-block;
	}
	.staff-image-container {
		border-radius: 4px !important;
		display: block;
		overflow: hidden;
		width: 100%;
		background-color: #EEE !important;
		max-width: 150px;
		margin-bottom: 15px;
	}
}


.frett-inner {
	.frett-image {
		display: block;
		position: relative;
		min-height: 200px;
	}
}

#spurning {
	background: $dokki;
	text-align: center;
	padding: 2em;

	h3 {
		text-transform: none !important;
		color: #FFF !important;
		padding-bottom: 1em;
	}

	color: $hviti !important;

	a.takki {
		display: block;
		margin: 0;
		font-size: 0.9em;
		text-transform: none !important;
		padding: 0.4em 1.2em;		
	}


}